<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12" :offset="9">
        <el-upload
          :action="$cons.API_SERVER_PATH + $api.UPLOAD.HEADPATH"
          :headers="uploadHeader"
          :data="{userId: this.userId, type: 'headImage'}"
          :before-upload="beforeFileUpload"
          :on-success="uploadFileSuc"
          :show-file-list="false"
          class="el-uploader">
          <div class="avatar">
              <img :src="licenseUrl ? licenseUrl : uploadUrl" alt="">
          </div>
          <div class="tip" v-if="licenseUrl">点击重新上传</div>
          <div class="tip" v-else>点击上传头像</div>
        </el-upload>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    computed: {
      userHead: {
        get () { return this.$store.state.user.userHead },
        set (val) { this.$store.commit('user/updateUserHead', val) }
      },
      userId: {
        get () { return this.$store.state.user.id }
      }
    },
    data() {
      return {
        dataForm: {
          headPath: ''
        },
        labelPosition: 'right',
        uploadUrl: require('@/assets/img/male.jpg'),
        licenseUrl: '',
        // 上传请求头部
        uploadHeader: {
          token: this.$cookie.get('marketToken')
        }
      }
    },
    created() {
    },
    methods: {
      submitForm () {
              this.$http({
                url: this.$http.adornUrl(this.$api.SYS.USER.HEAD_PATH),
                method: 'POST',
                params: this.$http.adornParams({
                  'headPath': this.dataForm.headPath
                }),
              }).then(({data}) => {
                if (data && data.code === 0) {
                  this.$message.success('头像修改成功！')
                  this.userHead = this.dataForm.headPath
                } else {
                  this.$message.error(data.msg)
                }
              })
      },
      
      // 附件上传前判断
      beforeFileUpload (file) {
        const isLt5M = file.size / 1024 / 1024 < 5
        if (!isLt5M) {
          this.$message.error('上传头像大小不能超过 5MB!')
        }
        return isLt5M
      },
      uploadFileSuc (response, file) {
        if (response && response.code === 0) {
          this.dataForm.headPath = response.path
          this.licenseUrl = URL.createObjectURL(file.raw)
          this.$forceUpdate()
          this.submitForm()
        } else {
          this.$message.error('头像上传失败')
        }
      },
      resetForm () {
        this.$refs['dataForm'].resetFields()
      }
    }
  }
</script>

<style scope>
  .i-title h3 {
    margin: 7px 0 12px;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 12px;
    border-bottom: 1px dashed #E4E7ED;
  }
  
  .el-uploader img {
    width: 180px;
    height: 180px;
  }
  .el-uploader .tip {
    background-color: #333;
    color: #fff;
    width: 180px;
    height: 35px;
    line-height: 32px;
    position: absolute;
    bottom: 0;
    font-size: 12px;
    opacity: .6;
  }
</style>
